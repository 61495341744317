import React from 'react';
import styled from 'styled-components';
import GithubIcon from '../../images/svg/github-icon.svg';

const HeadingSection = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ProjectsHeadingTitle = styled.h1`
  font-size: 6vmin;
  margin-bottom: 0;

  @media screen and (max-width: 600px) {
    font-size: 8vmin;
  }
`;

const ProjectsHeadingDescriptionDiv = styled.div`
  line-height: 1.4;

  @media screen and (max-width: 600px) {
    position: relative;
    width: 225px;
  }
`;

const DescriptionParagraph = styled.p`
  font-size: 18px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

const GithubAnchor = styled.a`
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: black;
  }
  margin-left: 5px;
`;

const GithubIconSvg = styled(GithubIcon)`
  margin-right: 3px;
`;

const ProjectsBar = () => {
  return (
    <HeadingSection>
      <ProjectsHeadingTitle>Projects</ProjectsHeadingTitle>
      <ProjectsHeadingDescriptionDiv>
        <DescriptionParagraph>
          This are my personal projects, some of them are on
          <GithubAnchor
            href='https://github.com/bojackhorseman0309'
            name='github'
            rel='noopener noreferrer'
          >
            <GithubIconSvg
              width={15}
              height={15}
              aria-labelledby='github'
              role='presentation'
            />
            Github
          </GithubAnchor>
        </DescriptionParagraph>
      </ProjectsHeadingDescriptionDiv>
    </HeadingSection>
  );
};

export default ProjectsBar;
