import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Shared/layoutWhite';
import ProjectsTable from '../components/ProjectPage/ProjectsTable';
import ProjectsBar from '../components/ProjectPage/ProjectsBarColumn';
import { graphql } from 'gatsby';

const ProjectsTableSection = styled.section`
  margin-top: 30px;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
    margin-bottom: 40px;
    width: 80%;
  }
`;

const MainSection = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ProjectsPage = ({ data }) => {
  const projectNodes = data.allSanityProject.nodes;

  return (
    <Layout>
      <MainSection>
        {/* Heading */}
        <ProjectsBar />
        <ProjectsTableSection>
          <ProjectsTable projectsList={projectNodes} />
        </ProjectsTableSection>
      </MainSection>
    </Layout>
  );
};

export const projectsDataQuery = graphql`
  query projectsDataQuery {
    allSanityProject {
      nodes {
        _id
        projectName
        projectShortDescription
        _rawProjectLongDescription
        projectSocials {
          socialProjectLink
          social {
            socialName
            socialLogo {
              caption
              alt
              asset {
                gatsbyImageData(placeholder: BLURRED, width: 20, height: 20)
              }
            }
          }
        }
        projectSlug {
          current
        }
      }
    }
  }
`;

export default ProjectsPage;
